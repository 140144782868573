import React from 'react';

import TabMenu from 'components/TabMenu/TabMenu';
import { TabProps } from 'components/TabMenu/TabMenu';

interface SelectedTileContentProps {
  video?: string;
  tabs?: TabProps[];
  handleClose: () => void;
  handleSetTab?: (id: string | number) => void;
  activeTab?: string | number;
  children?: React.ReactNode;
}

export const SelectedTileContent = (props: SelectedTileContentProps) => {
  const { tabs, children } = props;

  return (
    <div className="grid" grid-flow="column" grid-align-items="center">
      {tabs && tabs.length && (
        <React.Fragment>
          <TabMenu sectionId={props.activeTab} tabs={tabs} handleOnClick={props.handleSetTab} />
        </React.Fragment>
      )}
      {children ? children : <TileVideo url={props.video} />}
      <div className="buttons buttons--center">
        <button className="button button--primary" onClick={props.handleClose}>
          <i className="fa fa-backward" /> Zpět
        </button>
      </div>
    </div>
  );
};

const TileVideo = (props: { title?: string; url?: string }) => (
  <iframe
    title={props.title || 'Nápověda'}
    width="560"
    height="315"
    src={props.url}
    frameBorder="0"
    allow="autoplay; encrypted-media"
    allowFullScreen
  />
);

type TileProps = {
  id: number;
  title: string;
  icon: string;
  handleOnClick?: (id: number) => void;
  src?: string;
  video?: string;
};

export const Tile = (props: TileProps) => {
  const ButtonTag = props.src ? `a` : `button`;

  return (
    <ButtonTag
      className="SignPost__item--link"
      href={props.src || undefined}
      target={props.src ? '_blank' : undefined}
      rel={props.src ? 'noopener noreferrer' : undefined}
      onClick={props.handleOnClick ? () => props.handleOnClick!(props.id) : undefined}
    >
      <h3 className="_title">
        <i className={`fa fa-${props.icon}`} /> {props.title}
      </h3>
    </ButtonTag>
  );
};
