import './TabMenu.css';
import React from 'react';
import { Link } from 'react-router-dom';

export type TabProps = {
  title: React.ReactNode;
  id: string | number;
  active?: boolean;
  link?: string;
  handleOnClick?: (id: string | number) => void;
  className?: string;
};
const Tab: React.FC<TabProps> = ({ active, id, title, link, handleOnClick, className }) => {
  const tabProps = {
    to: link,
    children: title,
    className: ['TabMenu__tab', className && className].filter(Boolean).join(' '),
    onClick: () => {
      return handleOnClick ? handleOnClick(id) : null;
    },
    'data-active': active || null,
  };

  return link ? <Link {...tabProps} /> : <span {...tabProps} />;
};

interface TabMenuProps<T extends TabProps> {
  tabs: Array<T>;
  sectionId?: string | number;
  handleOnClick?: (id: T['id']) => void;
}

const TabMenu = <T extends TabProps>(props: TabMenuProps<T>) => {
  const { tabs, sectionId, handleOnClick } = props;

  if (!tabs || !tabs.length) {
    return null;
  }

  return (
    <div className="TabMenu">
      {tabs.map((tab: TabProps) => (
        <Tab
          {...tab}
          active={sectionId ? sectionId === tab.id : false}
          handleOnClick={handleOnClick}
          key={`tabMenu-${tab.id}`}
        />
      ))}
    </div>
  );
};

export default TabMenu;
